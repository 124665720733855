html, body {
  min-width: 513px; 
  overflow-x: hidden; 
  height: calc(var(--vh, 1vh) * 100);
}

.container {
  text-align: center;
  font-family: Arial, sans-serif;

  padding: 20px; /*수정1*/
  padding-bottom: 0; /*수정7*/
  height: calc(var(--vh, 1vh) * 100);
  box-sizing: border-box; /*수정8*/

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.progress-bar {
  width: min(40rem, calc(100vw - 3rem));
  height: 5px; /*수정2*/
  background-color: #ddd; /*수정3*/
  border-radius: 9999px;
  margin-top: 3rem;
  margin-bottom: auto;
  display: block;
}

.progress {
  height: 5px;
  width: 100%;
  background-color: #58cc02;
  border-radius: 5px;
}

h1 {
  font-size: 1.8rem; /*수정4*/
  font-weight: bold;
  margin-bottom: 20px; /*수정5*/
}

.subtext {
  color: #888;
  font-size: 14px;
  padding-bottom: 20px;
  margin-top: 0;
  margin-bottom: 0;
}

.subtext-context {
  margin: 0;
}

.options {
  display: flex;
  justify-content: space-around;
  margin-top: 30px;
  margin-bottom: 30px; /*수정6*/
}

.option {
  width: 150px;
  height: 180px;
  padding: 10px;
  border: 1px solid transparent;
  border-color: #afafaf;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s, border-color 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  align-items: center;
}

.option:hover {
  background-color: #4caf4f6b;
}

.option.selected {
  border-color: #4caf50;
  background-color: #f0f8f0;
}

.option img {
  margin-bottom: 10px;
  width: 100%;
  height: 70%;
}

#img3 {
  object-fit: cover;
}

.subtext-context {
  margin: 0;
  padding-top: 10px;
  font-size: 16px;
  color: #333;
}

.navigation {
  display: flex;
  justify-content: center;
  gap: min(32rem, calc(100vw - 20rem));

  width: 100vw;
  padding: 3rem 2rem;
  border-top: 2px solid #e5e5e5;
  margin-top: auto;
}

.prev-button,
.next-button {
  box-sizing: border-box;
  max-width: 8rem;
  width: 100%;

  padding: 1rem 1.5rem;
  border-radius: 1rem;

  cursor: pointer;

  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1rem;
}

.prev-button:hover,
.next-button:hover {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.3);
}

.prev-button {
  border: 2px solid #E1E1E1;
  background-color: #FFFFFF;
  color: #A6A6A6;
}

.next-button {
  border: 2px solid #85e478;
  background-color: #42C62F;
  color: #FFFFFF;
}


@media (max-width: 767px) {
  .progress-bar {
    width: 90%;
    height: 4px; 
    margin-top: 1rem;
  }

  .progress {
    height: 4px;
  }

  h1 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  .subtext-context {
    font-size: 14px;
  }

  .prev-button,
  .next-button {
    max-width: 6rem;
    font-size: 1rem;
    padding: 0.8rem 1.2rem;
  }
}