/* src/App.css */

:root {
    --vh: 1vh; /* 초기 값 설정 */
  }
/*
  body, html, #root {
    height: 100%;
    margin: 0;
    padding: 0;
  }
  
  .app-container {
    height: calc(var(--vh) * 100);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  */
  /* 예시: 풀 스크린 섹션
  .full-height-section {
    height: calc(var(--vh) * 100);
    width: 100%;
    background-color: lightblue;
  }
  
기타 필요한 스타일 */
  