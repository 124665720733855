.home {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: calc(var(--vh, 1vh) * 100);
  margin: 0 auto;
  position: relative;
}

.home .frame {
  align-items: flex-start;
  align-self: stretch;
  background-color: #ffffff;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  flex-grow: 1;
  padding: 0;
  position: relative;
  width: 100%;
}

.home .div {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 180px;
  justify-content: center;
  position: relative;
  width: 100%;
}

.home .group {
  height: 189px;
  position: relative;
  width: 366px;
}

.home .frame-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 70px;
  height: 114px;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 75px;
  width: 366px;
}

.home .div-wrapper {
  align-items: center;
  display: inline-flex;
  flex-direction: column;
  gap: 32px;
  height: 125px;
  margin-bottom: -5.50px;
  margin-left: -61.50px;
  margin-right: -61.50px;
  margin-top: -5.50px;
  position: relative;
}

.logo {
  height: 250px;
  width: 700px;
  opacity: 0; 
  transform: translateY(20px);
  animation: fadeInUp 1.0s ease-out forwards; 
}

@keyframes fadeInUp {
  0% {
    opacity: 0; 
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0); 
  }
}


.home .p {
  color: #000000;
  font-family: "STIXIntegralsUpSm-Regular", Helvetica;
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 2;
  position: relative;
  text-align: center;
  width: fit-content;
  margin-top: 50px;
}

.home .frame-2 {
  align-items: center;
  align-self: stretch;
  background-color: #ffffff;
  border-color: #e5e5e5;
  border-top-style: solid;
  border-top-width: 2px;
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  padding: 36px 0; /*padding: 36px 32px;*/
  position: relative;
  width: 100%;
}


.home .next-button {
  padding: 20px 30px;
  border: none;
  border-radius: 15px;
  cursor: pointer;
  font-size: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2); 
  transition: box-shadow 0.3s ease;
  display: inline-block; 
  width: auto;
  white-space: nowrap;
  text-align: center;
  height: auto;
}

.home .next-button:hover {
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.3); 
}

/* 반응형 스타일 (모바일: 767px 이하) */
@media (max-width: 767px) {

  .home .div {
    gap: 50px;
  }

  .logo {
    width: 300px;
    height: auto;
  }

  .home .p {
    font-size: 15px;
    line-height: 1.5;
    margin-top: 1px;
  }

  .home .next-button {
    padding: 15px 20px;
    font-size: 15px;
  }
}