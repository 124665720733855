#boundary {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);

  box-sizing: padding-box;
  width: 417px;
  height: 117px;

  z-index: -1;
}

#round-boundary {
  position: absolute;
  top: 0;
  left: 0;

  box-sizing: padding-box;
  height: 114px;
  border: 1.5px solid #4caf50;
  border-radius: 57.75px;
  transition: left 0.3s, right 0.3s;
}

#round-boundary.invisible {
  display: none;
}

#round-boundary.visible {
  display: block;
}
/* 
@media (max-width: 767px) {
  #boundary {
    width: 417px;
    height: 117px;
  }

  #round-boundary {
    height: 114px; 
  }
} */