.combo-container {
  display: flex;
  align-items: center;
  margin: 1rem 0;
}

.combo-label {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #58cc02;
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  text-align: center;
  margin-right: 2rem;
}

.combo-box {
  flex: 1;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  overflow: visible;
  position: relative;
  box-sizing: border-box
}

.items-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
  position: relative;
  z-index: 1;
}

.item-card {
  width: 150px;
  height: 180px;
  perspective: 1000px;
  position: relative;
  overflow: visible;
  flex-shrink: 0; 
  cursor: pointer;
}

.item-card.flipped .item-card-front {
  transform: rotateY(180deg);
}

.item-card.flipped .item-card-back {
  transform: rotateY(0deg);
}

.item-card-front .item-image-wrapper {
  overflow-y: hidden;
}

.item-card-back .item-image-wrapper {
  overflow-x: hidden;
  display: block;  
  overflow-y: auto;
  max-height: 100%; 
  width: 100%;    
  padding: 10px; 
  box-sizing: border-box; 
}

.item-card-front,
.item-card-back {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  backface-visibility: hidden;
  transition: transform 0.6s;
  border-radius: 8px;
}

.item-card-front {
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  transform: rotateY(0deg);
  border: 1px solid #ddd;
  overflow: visible !important; /* 내부 요소 잘리지 않도록 설정 */
  position: relative; /* z-index 관리 */
}

.item-card-back {
  background: #f5f5f5;
  transform: rotateY(-180deg);
  display: flex;
  align-items: center;
  justify-content: center;
}

.item-card-back .item-details {
  opacity: 0;
  transition: opacity 0.3s ease-in-out 0.2s;
}

.item-card.flipped .item-card-back .item-details {
  opacity: 1;
}

.item-card:not(.flipped) .item-card-back .item-details {
  opacity: 0;
  transition: opacity 0s ease-in-out;
}

.item-card .item-order {
  position: absolute;
  top: -10px;
  left: 5px;
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  color: #adadad;
  border: 2px solid #adadad;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  font-weight: bold;
  z-index: 10 !important;
  overflow: visible;
}

.item-name {
  margin: 10px 0 5px;
  font-size: 14px;
  font-weight: bold;
  color: #333;
  text-align: center;
}

.item-image-wrapper {
  width: 90%;
  height: 75%;
  overflow-y: auto;
  padding: 10px;
}

.item-card img.item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.item-details {
  text-align: justify;
  font-size: 0.9rem;
  line-height: 1.4;
}

.item-details div {
  margin-bottom: 8px;
}

.item-details strong {
  font-weight: bold;
}

.more-btn {
  margin-bottom: 1rem;
  padding: 0.5rem 2rem;
  background-color: #ffffff;
  border: 1px solid #adadad;
  color: #adadad;
  border-radius: 10px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.more-btn:hover {
  background-color: #adadad;
  color: #ffffff;
}

@media (max-width: 767px) {
  .combo-container {
    overflow: visible; 
    position: relative;
  }

  .combo-box {
    padding: 0.5rem;
    white-space: nowrap;
    overflow: visible;
    overflow-x: auto;
    overflow-y: visible; 
    position: relative;
    z-index: 0;
  }

  .items-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 1rem;
    width: calc(150px * 2 + 1rem * 1); 
    flex-wrap: nowrap;
    overflow-x: auto; 
    position: relative;
    overflow: visible;
    z-index: 1;
  }

  .item-card {
    position: relative;
    overflow: visible; 
    flex-shrink: 0;
  }

  .item-details {
    text-align: justify; 
    font-size: 0.9rem;
    line-height: 1.4;
    white-space: normal; 
    word-wrap: break-word; 
    overflow-wrap: break-word; 
  }
  

  .item-card .item-order {
    position: absolute;
    top: -8px; 
    font-size: 0.9rem;
  }
}


